<template>
  <div class="row">

    <!-- MYTRIP MONTH AND CATEGORY CHART -->
    <div class="col-12 title" v-has-permission="`see my trip`">
      <h4>mytrip charts</h4>
    </div>
    <div class="col-6" v-has-permission="`mytrip_monthsChart`">
      <monthly-chart></monthly-chart>
    </div>
    <div class="col-6"  v-has-permission="`mytrip_categoryChart`">
      <group-type-chart></group-type-chart>
    </div>
    <!-- END MYTRIP MONTH AND CATEGORY CHART -->

  </div>

</template>
<script>
    import monthlyChart from "../../components/Mytrip/MonthlyChart.vue";
    import groupTypeChart from "../../components/Mytrip/GroupTypeChart.vue";
export default {
  components: {
      monthlyChart,
      groupTypeChart
  },
  data() {
    return {

    };
  },
  computed: {

  },
  methods: {
      checkUser(){
          User.prototype.check();
      }
  },
  mounted() {
      this.checkUser();
  },
  beforeDestroy() {

  }
};
</script>
<style></style>
